import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Grid from '@material-ui/core/Grid'
import heroBg from '../images/portraits/Travis_N.jpg'
import { isMobile } from 'react-device-detect'

const IndexPage = () => {
  return (
    <Layout
      background={`url(${heroBg}) no-repeat fixed top`}
      backgroundSize={'cover'}
    >
      <SEO title="Homeless or Homebody?" />
      <Grid container justify="center">
        <Grid item>
          <div style={{ maxWidth: `100px`, marginBottom: `1.45rem` }}></div>
        </Grid>
        <Grid item xs={10} lg={8}>
          <div style={{backgroundColor:'black', opacity: '0.8', padding:'.25rem', margin: isMobile ? '500px 0 100px 0' : '700px 0 350px 0'}}>
            <h1>Homeless or Homebody?</h1>
            <h2 style={{margin:'0'}}>
              A Minneapolis mural project of thought-provoking portraits
              challenging stereotypes of homelessness.
            </h2>
          </div>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default IndexPage
